<template>
	<div>
		<v-row v-if="loading">
			<v-col cols="12" md="6" lg="3" v-for="loader in 8" :key="loader">
				<v-skeleton-loader
					v-bind="attrs"
					type="card"
					height="400"
					width="100%"
				></v-skeleton-loader>
			</v-col>
		</v-row>
		<div v-else>
			<div class="tw-pb-6">
				<div class="tw-flex tw-items-center tw-justify-between">
					<h2 class="tw-text-xl tw-font-semibold tw-mb-7">{{ $t('products') }}</h2>
					<!-- <div class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer">
				View All
				<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
				<v-icon color="#F2CA51" class="tw--ms-4">mdi-chevron-right</v-icon>
			</div>-->
					<div>
						<!-- <v-btn
							:color="selectedType === 'products' ? '#722432' : '#FFFFFF'"
							@click="selectedType = 'products'"
							class="tw-w-125px tw-h-12 tw-me-4"
							depressed
						>
							<span
								:class="
									selectedType === 'products' ? 'tw-text-white' : 'tw-text-CB'
								"
								class="tw-text-base"
								>Products</span
							>
						</v-btn> -->
						<!-- <v-btn
						:color="selectedType === 'offers' ? '#722432' : '#FFFFFF'"
						@click="selectedType = 'offers'"
						class="tw-w-125px tw-h-12 tw-me-4"
						depressed
					>
						<span
							:class="
								selectedType === 'offers' ? 'tw-text-white' : 'tw-text-CB'
							"
							class="tw-text-base"
							>Offers</span
						>
					</v-btn> -->
						<v-btn
							tile
							icon
							outlined
							color="#D8D8D8"
							class="tw-rounded tw-w-12 tw-h-12 tw-me-4"
							@click="filterDrawer = !filterDrawer"
						>
							<v-icon color="black">mdi mdi-sort-ascending</v-icon>
						</v-btn>
						<v-btn
							tile
							icon
							outlined
							color="#D8D8D8"
							class="tw-rounded tw-w-12 tw-h-12"
							@click="sortByDrawer = !sortByDrawer"
						>
							<v-icon color="black">mdi mdi-filter</v-icon>
						</v-btn>
					</div>
				</div>
				<!-- <div class="tw-flex tw-flex-wrap tw-space-s-4 lg:tw-space-s-9">
					<CategoryCard
						v-for="(item, index) in $productCategories.data"
						:item="item"
						:key="index"
						:isSelected="selectedCategoryId === item.id"
						@onClick="onSelectCategory"
					/>
				</div> -->
				<!-- <VueSlickCarousel
					:slidesToShow="$productCategories.data.length"
					v-bind="Settings"
				>
					<CategoryCard
						v-for="(item, index) in $productCategories.data"
						:item="item"
						:key="index"
						:isSelected="selectedCategoryId === item.id"
						@onClick="onSelectCategory"
						class="tw-m-4"
					/>
				</VueSlickCarousel> -->
				<v-row class="tw-mt-9">
					<v-col
						v-for="(item, i) in $favoriteProducts.data"
						:key="i"
						cols="12"
						sm="6"
						md="4"
						lg="3"
						xl="2"
					>
						<ProductCard
							@onClick="goToSingleProduct"
							@onHover="handleOnHover"
							@fetchFavorite="refetchFavorite"
							:productInfo="
								selectedType == 'products' ? item : item.products[0]
							"
							class="tw-flex-none"
							:isGreyQuantityBg="false"
						/>
					</v-col>
					<!-- {{filterSelectedProduct.data}} -->
				</v-row>

				<v-dialog
					content-class="tw-rounded-2xl tw-shadow-none"
					v-model="showProductDialog"
					transition="dialog-bottom-transition"
					max-width="1020"
				>
					<ProductCardFull
						dense
						:product="onHoverProduct"
						class="tw-bg-white tw-rounded-2xl tw-p-8"
					/>
				</v-dialog>

				<v-card elevation="0" class="tw-rounded-3xl" v-model="sortByDrawer">
					<v-navigation-drawer
						class="2xl:tw-rounded-3xl lg:tw-rounded-xl tw-rounded-lg tw-w-64 md:tw-w-80 2xl:tw-w-420"
						v-model="sortByDrawer"
						fixed
						temporary
						right
					>
						<div
							class="tw-px-4 md:tw-px-10 tw-pt-6 md:tw-pt-10 xl:tw-pt-16"
						>
							<div class="tw-flex tw-items-center tw-space-s-10">
								<v-btn
									@click="sortByDrawer = !sortByDrawer"
									class="tw-w-12 tw-h-12"
									icon
								>
									<v-icon large>mdi-chevron-left</v-icon>
								</v-btn>
								<h3 class="tw-text-2xl tw-font-semibold">Sort by</h3>
							</div>
							<div class="tw-mt-5 xl:tw-mt-11">
								<v-radio-group v-model="sortBy">
									<v-radio
										color="#F2CA51 tw-text-black"
										class="tw-text-xl tw-mt-6"
										:value="'highToLow'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												High to Low Price
											</div>
										</template>
									</v-radio>
									<v-radio
										color="#F2CA51"
										class="tw-text-xl tw-mt-6"
										:value="'lowToHigh'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												Low to High Price
											</div>
										</template>
									</v-radio>
									<v-radio
										color="#F2CA51"
										class="tw-text-xl tw-mt-6"
										:value="'newItems'"
									>
										<template v-slot:label>
											<div class="tw-text-xl tw-text-black">
												New Items
											</div>
										</template>
									</v-radio>
								</v-radio-group>
							</div>
						</div>
						<template v-slot:append>
							<div
								block
								class="tw-mx-4 lg:tw-mx-10 tw-mb-4 md:tw-mb-8 xl:tw-mb-14"
							>
								<v-btn
									class="tw-rounded-lg md:tw-h-14"
									depressed
									dark
									color="#722432"
									block
									@click="sortProduts"
								>
									<span
										class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
										>Sort Result</span
									>
								</v-btn>
							</div>
						</template>
					</v-navigation-drawer>
				</v-card>
				<v-card elevation="0" class="tw-rounded-3xl" v-model="filterDrawer">
					<v-navigation-drawer
						class="tw-rounded-3xl tw-w-64 md:tw-w-80 2xl:tw-w-420"
						v-model="filterDrawer"
						fixed
						temporary
						right
					>
						<div class="tw-px-4 md:tw-px-10 tw-pt-16">
							<div class="tw-flex tw-items-center tw-space-s-10">
								<v-btn
									@click="filterDrawer = !filterDrawer"
									class="tw-w-12 tw-h-12"
									icon
								>
									<v-icon large>mdi-chevron-left</v-icon>
								</v-btn>
								<h3 class="tw-text-2xl tw-font-semibold">Filters</h3>
							</div>
							<div>
								<v-text-field
									height="54"
									class="custom-outline-text-field tw-rounded-md tw-my-4 lg:tw-my-11"
									outlined
									hide-details="false"
									v-model="filters.query"
								>
									<v-icon slot="prepend-inner" color="#413434"
										>mdi-magnify</v-icon
									>
								</v-text-field>
								<div>
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										Show Categories
									</h2>
									<div
										class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
									>
										<div
											v-for="(category,
											i) in $productCategories.data"
											:key="i"
											@click="selectedCategoryId = category.id"
											class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
											:class="
												category.id == selectedCategoryId
													? 'tw-bg-F2 '
													: 'tw-border tw-border-E9 '
											"
										>
											<span
												:class="
													category.id == selectedCategoryId
														? ' tw-text-white'
														: 'tw-text-black tw-opacity-70'
												"
												class="tw-text-base"
												>{{ category.name }}</span
											>
										</div>
									</div>
								</div>
								<div
									v-if="
										selectedCategory &&
											selectedCategory.childCategories &&
											selectedCategory.childCategories.length
									"
									class="lg:tw-mt-8 tw-mt-4"
								>
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										Sub Categories
									</h2>
									<div
										class="tw-flex tw-space-s-2 tw-flex-wrap tw--ms-2 tw--mt-2"
									>
										<div
											v-for="(subCategory,
											i) in selectedCategory.childCategories"
											:key="i"
											:class="
												subCategory.id == filters.subCategory
													? 'tw-bg-F2 '
													: 'tw-border tw-border-E9 '
											"
											class="tw-rounded-md tw-py-2.5 tw-ps-4 tw-pe-5 tw-mt-2 tw-ms-2 tw-cursor-pointer"
											@click="filters.subCategory = subCategory.id"
										>
											<span
												:class="
													subCategory.id == filters.subCategory
														? ' tw-text-white'
														: 'tw-text-black tw-opacity-70'
												"
												class="tw-text-base"
												>{{ subCategory.name }}</span
											>
										</div>
									</div>
								</div>
								<div class="lg:tw-my-8 tw-my-4">
									<h2
										class="tw-text-19px tw-font-medium tw-mb-4 xl:tw-mb-8 tw-text-black"
									>
										Price Range
									</h2>
									<v-slider
										tick-size="32"
										track-color="#F4F4F4"
										class="price-range"
										thumb-color="#FFFFFF"
										hide-details="auto"
										color="#F2CA51"
										v-model="filters.price_range_up"
										hint="Im a hint"
										:min="minRange"
										:max="maxRange"
									></v-slider>
									<div
										class="tw-flex tw-justify-between tw-items-center tw-px-2 tw-mt-3"
									>
										<span class="tw-font-Rubik-text tw-text-D2"
											>{{ minRange }} SAR</span
										>
										<span class="tw-text-F2 tw-font-Rubik-text"
											>{{ filters.price_range_up }} SAR</span
										>
										<span class="tw-font-Rubik-text tw-text-D2"
											>{{ maxRange }} SAR</span
										>
									</div>
								</div>
							</div>
						</div>
						<template v-slot:append>
							<div block class="tw-mx-4 lg:tw-mx-10 tw-mb-14">
								<v-btn
									class="tw-rounded-lg md:tw-h-14"
									depressed
									@click="filterProducts"
									dark
									color="#722432"
									block
								>
									<span
										class="tw-text-lg tw-capitalize tw-font-medium tw-text-white"
										>Sort Result</span
									>
								</v-btn>
							</div>
						</template>
					</v-navigation-drawer>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import { omitEmpties } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { numberWithCommas } from '../helpers'

import ProductCard from '../components/customs/ProductCard.vue'
import ProductCardFull from '../components/customs/ProductCardFull.vue'

export default {
	name: 'Favorites',
	components: {
		ProductCard,
		ProductCardFull,
	},
	data() {
		return {
			selectedCategoryId: null,
			selectedType: 'products',
			showProductDialog: false,
			quantity: 1,
			onHoverProduct: null,
			sortByDrawer: false,
			loading: true,
			items: [
				{ title: 'Home', icon: 'mdi-home-city' },
				{ title: 'My Account', icon: 'mdi-account' },
				{ title: 'Users', icon: 'mdi-account-group-outline' },
			],
			filters: {
				query: '',
				mainCategory: '',
				subCategory: '',
				price_range_up: null,
			},
			//carousel settings
			Settings: {
				arrows: true,
				focusOnSelect: true,
				infinite: true,
				speed: 500,
				initialSlide: 0,
				slidesToShow: 3,
				variableWidth: true,
			},
			perPage: 15,
			minRange: 0,
			maxRange: 80000,
			sortBy: '',
			filterDrawer: false,
			selectedImage: 0,
		}
	},

	mounted() {},

	computed: {
		...mapGetters('products', ['$favoriteProducts']),
		...mapGetters('category', ['$productCategories']),
		...mapGetters('offers', ['$offers']),
		...mapGetters('auth', ['$currentUser']),

		// filterSelectedProduct() {
		// 	return this.selectedType == "products" ? this.$favoriteProducts : this.$offers;
		// },
		selectedCategory() {
			// return []
			return this.$productCategories.data.length
				? this.$productCategories.data.find(
						(cat) => cat.id == this.selectedCategoryId
				  )
				: {}
		},
		maxQuantity() {
			if (!this.onHoverProduct || !this.onHoverProduct.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.onHoverProduct.quantity
		},
	},
	methods: {
		...mapActions('products', ['getFavoriteProducts']),
		...mapActions('category', ['getCategories']),
		...mapActions('offers', ['getRunningOffers']),

		async onSelectCategory(id) {
			this.selectedCategoryId = id
			this.getFavoriteProducts({ main_category: id })
		},
		handleOnHover(item) {
			this.showProductDialog = true
			this.onHoverProduct = item
		},
		handleOnLeave() {
			this.showProductDialog = false
			setTimeout(() => {
				this.onHoverProduct = null
			}, 1000)
		},
		formatPrice(price) {
			console.log(price)
			return numberWithCommas(price)
		},
		goToSingleProduct(id) {
			console.log('goToSingleProduct')
			this.$router.push(`product/${id}`)
		},
		async sortProduts() {
			let priceOrderBy = this.sortBy === 'lowToHigh' ? 'asc' : 'desc'
			let orderByColumn = this.sortBy === 'newItems' ? 'created_at' : 'price'
			console.log(priceOrderBy, orderByColumn, 'priceOrderBy')
			// this.getFavoriteProducts({ priceOrderBy: this.sortBy,orderByColumn:priceOrderBy });
			this.getFavoriteProducts(
				omitEmpties({
					priceOrderBy: priceOrderBy,
					orderByColumn: orderByColumn,
					mainCategory: this.selectedCategoryId,
					perPage: 25,
				})
			)
		},
		async filterProducts() {
			this.getFavoriteProducts(
				omitEmpties({
					...this.filters,
					mainCategory: this.selectedCategoryId,
					perPage: 25,
				})
			)
		},
		refetchFavorite() {
			this.getFavoriteProducts({ perPage: 25 })
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		async onAddToCart() {
			if (this.isUpdating) return
			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'product',
				quantity: this.quantity,
				cartableId: this.onHoverProduct.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success('Item Added to cart.')
			else this.$toast.error('Error while adding item to cart.')
		},
	},
	created() {
		this.getFavoriteProducts({ perPage: 25 })
		this.getCategories({ type: 'product', perPage: 0 })
		this.loading = false
	},
}
</script>

<style lang="scss">
// .custom-outline-text-field .v-text-field--outlined >>> fieldset {
// 	border-color: rgba(192, 0, 250, 0.986);
// }
.custom-outline-text-field.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.price-range .v-slider__thumb {
	background-color: rgb(255, 255, 255);
	border-color: #d9d9d9 !important;
	border: 1px solid;
	width: 32px !important;
	height: 32px !important;
}
.price-range .v-slider__track-container {
	height: 8px !important;
}
.price-range .v-slider__track-fill,
.v-slider__track-background {
	border-radius: 6px !important;
}
.price-range .v-slider__thumb:after {
	display: block;
	width: 18px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	margin-top: 1px;
}
</style>
