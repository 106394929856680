<template>
	<v-container fluid>
		<v-row>
			<v-col
				cols="12"
				md="6"
				class="tw-flex tw-flex-col"
				:class="[dense ? '' : 'lg:tw-pe-24']"
			>
				<div :class="[dense ? 'lg:tw-ps-2 lg:tw-pe-8' : '']">
					<h1
						class="tw-font-bold tw-text-2xl tw-mb-3"
						:class="[dense ? 'xl:tw-text-4xl' : '3xl:tw-text-6xl']"
					>
						{{ product.name }}
					</h1>
					<p
						class="tw-m-0 tw-text-justify product__description"
						:class="[
							dense
								? 'xl:tw-text-lg product__description-dense'
								: 'tw-text-xl 3xl:tw-text-3xl',
						]"
					>
						{{ product.description }}
					</p>
					<div
						class="md:tw-mt-16 md:tw-mb-24 tw-my-6 tw-py-4 tw-border-t tw-border-b tw-border-E5 tw-flex-1"
					>
						<v-row>
							<v-col cols="6" md="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('seller')
									}}</span>
									<h3
										class="tw-font-semibold tw-mt-2 tw-mb-3"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ product.supplier.representativeName }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('availability')
									}}</span>
									<h3
										class="tw-font-medium tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ product.status }}
									</h3>
								</div>
							</v-col>
							<v-col cols="6" md="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('pieces')
									}}</span>
									<h3
										class="tw-font-semibold tw-mt-2 tw-mb-3"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{
											product.isSpecifiedQuantity
												? product.quantity
												: '-'
										}}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('sizes')
									}}</span>
									<h3
										class="tw-font-medium tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										<div class="tw-flex tw-items-center">
											<span>{{ getSizes(product.sizes) }}</span>
										</div>
									</h3>
								</div>
							</v-col>

							<v-col cols="6" md="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('size')
									}}</span>
									<h3
										class="tw-font-semibold tw-mt-2 tw-mb-3"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ $get(product, 'sizes.0.name', '-') }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('category')
									}}</span>
									<h3
										class="tw-font-medium tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ $get(product, 'mainCategory.0.name') }}
									</h3>
								</div>
							</v-col>

							<v-col cols="6" md="3">
								<div>
									<span class="tw-text-13px tw-font-light">{{
										$t('headers.color')
									}}</span>
									<h3
										class="tw-font-semibold tw-mt-2 tw-mb-3"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ $get(product, 'colors.0.name', '-') }}
									</h3>
									<span class="tw-text-13px tw-font-light">{{
										$t('subCategory')
									}}</span>
									<h3
										class="tw-font-medium tw-mt-2.5 tw-text-black tw-font-Poppins-text"
										:class="[dense ? 'tw-text-sm' : 'tw-text-base']"
									>
										{{ $get(product, 'subCategory.0.name') }}
									</h3>
								</div>
							</v-col>
						</v-row>
					</div>

					<div
						v-if="offerPrice"
						class="tw-me-3 tw-text-xl tw-font-medium tw-font-Rubik-text tw-opacity-50 tw-line-through"
					>
						SAR {{ $commafy(product.price || 0) }}
					</div>
					<div
						class="tw-flex tw-items-center tw-justify-between tw-flex-none"
					>
						<div class="tw-flex tw-items-end">
							<h2
								class="tw-text-3xl xl:tw-text-4xl tw-font-medium tw-font-Rubik-text"
							>
								{{ $commafy(offerPrice || product.price || 0) }}
							</h2>
							<span class="tw-ms-3">SAR</span>
						</div>
						<div
							v-if="product.status === 'in-store'"
							class="tw-rounded-md tw-space-s-4 tw-flex tw-items-center"
						>
							<base-quantity
								:large="!dense"
								:class="[dense ? 'tw-bg-gray-50' : '']"
								:max="maxQuantity"
								:size="dense ? 48 : 56"
								:icon-ratio="2.8"
								:disabled="product.status !== 'in-store'"
								v-model="quantity"
								@change="onChangeQuantity"
							/>
							<v-btn
								tile
								dark
								depressed
								color="#F5CA15"
								style="background-color: #f5ca15 !important"
								class="tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10"
								:small="dense"
								:class="[
									dense ? 'tw-w-14 tw-h-12' : 'xl:tw-h-14 xl:tw-w-16',
								]"
								:loading="isUpdating"
								:disabled="product.status !== 'in-store'"
								@click="onAddToCart"
							>
								<icon-cart-white :size="dense ? 20 : 24" />
							</v-btn>
						</div>
						<div v-else class="tw-capitalize tw-font-medium error--text">
							{{ $get(product, 'status', '').split('-').join(' ') }}
						</div>
					</div>
				</div>
			</v-col>
			<v-col cols="12" md="6" :class="[dense ? '' : 'lg:tw-ps-24']">
				<v-img
					cover
					ref="imgRef"
					:height="dense ? 308 : 420"
					:src="
						$image(
							$get(product, `attachments.${selectedImage}.attachedLink`)
						)
					"
					class="tw-rounded-10px tw-w-full tw-bg-gray-100"
					@load="calculateImgWidth"
					@error="$onImgError({ ref: $refs.imgRef })"
				/>
				<base-slideable
					:value="product.attachments.length"
					show-arrows-on-hover
					class="tw-mt-3.5"
				>
					<div class="tw-flex tw-space-s-3.5">
						<div v-for="(image, i) in product.attachments" :key="i">
							<v-img
								:width="imgWidth / 3 - 8"
								:src="$image(image.attachedLink)"
								:class="{
									'tw-border-F2 tw-border-2 tw-bg-F2':
										i === selectedImage,
								}"
								alt
								ref="sImgRef"
								class="tw-h-32 tw-object-cover tw-object-center tw-rounded-10px tw-border tw-backdrop-opacity-20"
								@click="selectedImage = i"
								@error="$onImgError({ ref: $refs.sImgRef, index: i })"
							/>
						</div>
					</div>
				</base-slideable>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { truncate } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'ProductCardFull',
	props: {
		dense: {
			type: Boolean,
			default: false,
		},
		product: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			quantity: 1,
			imgWidth: 477,
			selectedImage: 0,
			isUpdating: false,
		}
	},
	mounted() {
		this.calculateImgWidth()
	},
	watch: {
		'cartItem.quantity': {
			immediate: true,
			handler(v) {
				this.quantity = v || 1
			},
		},
	},
	computed: {
		...mapGetters('cart', ['$cart']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('products', ['$products']),
		...mapGetters('category', ['$productCategories']),
		offerPrice() {
			return this._.get(this.product, 'singleOffer.0.finalPrice', 0)
		},
		maxQuantity() {
			if (!this.product || !this.product.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.product.quantity
		},
		isInCart() {
			return !!this.cartItem
		},
		cartItem() {
			return this.$cart.products.data.find((cartItem) => {
				return cartItem.cartableId == this.product.id
			})
		},
	},
	methods: {
		...mapActions('cart', ['updateCartItem']),
		calculateImgWidth() {
			if (!this.$refs.imgRef) return
			const width = this.$refs.imgRef.$el.getBoundingClientRect().width
			this.imgWidth = width > 477 ? width : 477
		},
		async onAddToCart() {
			if (this.isUpdating) return

			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'product',
				quantity: this.quantity,
				cartableId: this.product.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success(this.$t('success.addedToCart'))
			else this.$toast.error(this.$t('error.errorAddingCart'))
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		getSizes(sizes) {
			return sizes.length ? sizes.map((a) => a.name[0]).join('-') : '-'
		},
		goToSingleProduct(id) {
			this.$router.push(`/product/${id}`)
		},
		formatProductName(name) {
			return truncate(name, {
				length: 104,
				separator: '...',
			})
		},
	},
}
</script>

<style lang="scss">
.product {
	&__description {
		&-dense {
			@include multiLineEllipsis(1.5em, 5);
		}
	}
}
</style>
